.currentLocationButton {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius:50%;
  padding: 0 0 0 0;
  display: flex;
  
  
}

.currentLocationButton span {
  width: 22px;
  height: 22px;
  background: url('/src/assets/weather-sprite.png') 0px 0px no-repeat;
  display: block;
  margin: 9px 0 0 9px;
  
}
 