main {
  
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.current {
  background: #1e213a;
  height: 100vh;
  position: relative;
  /* width: 100vw; */
  /* display: none; */
  overflow: hidden;
}

.forecasts-highlights {
  background: #100e1d;
  position: relative;
  
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  main {
  }
  .current {
    height: 30vh;
    position: relative;
    

  }
  .forecasts-highlights {
    /* height: 70vh; */
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1024px) {
  main {
    flex-direction: row;
  }
  .current {
    width: 450px;
    height: auto;
    
  }
  .forecasts-highlights {
    width:calc(100% - 450px)
    
  }
}

@media screen and (min-width: 1200px) {
  
  .current {
    width: 450px;
  }
  
}


.loading-placeholder{
  width: 100%;
  top:0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items:center;
  position: absolute;
  z-index: 20;
  background-color:rgba(0,0,0,0.6);
}

.loading-placeholder span{ color:#fff}
