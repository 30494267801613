.searchPlacesButton {
  width: 161px;
  height: 40px;
  background: #6e707a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #6e707a;
  padding: 0 0 0 0;
  border: none;
}

.searchPlacesButton:focus{
  border: none;
}

.searchPlacesButton span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  
}
