.windSpeed {
  font-style: normal;
  line-height: 75px;
  margin: 14px 0 0;
}

.windSpeedNumber {
  font-size: 64px;  
  font-weight: bold;
}

.windSpeedUnit {
  font-size: 36px;
}

.windDirection {
  display: flex;
  justify-content: center;
}

.windDirection span {
  display: block;
  height: 30px;
  line-height: 30px;
  margin: 0 5px;
}
