.highlights{
  width:328px;
  margin: auto;
  
  
}

.highlights h1{
  color:#E7E7EB;
  font-size: 24px;
  font-weight: 400;
} 

.highlights-items{  
  width:100%;  
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.hightlight-item{
  margin: 0 0 32px 0;
}


@media screen and (min-width: 525px) {
  .highlights{
    width:412px;    
  }

  .highlights-items{  
        flex-direction: row;
        justify-content: space-between;

  }

}

@media screen and (min-width: 640px){
  .highlights{
    width:558px;    
  }

}

@media screen and (min-width: 768px) and (max-width: 1023px){
  .highlights{
    width:704px;    
  }

}

@media screen and (min-width: 1200px) {
  .highlights{
    width:704px;    
  }
    
}