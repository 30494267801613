.DateAndLocation{
  font-size: 18px;
  color:#88869D;
  
}

.dateContainer, .locationContainer {
  text-align: center;
}

.dotSpan{
  padding: 0 10px;
}

.locationContainer{
  margin-top: 2vh;
  
}



.locationContainer span{
  padding: 0 0 0 25px;
  position: relative;
  line-height: 20px;

}

.locationContainer span:before{
  content: "";
  width: 14px;
  height: 19px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: url('../../assets/weather-sprite.png') -40px 0 no-repeat;
}