.percentBar{
  width:calc(100% - 100px);
  margin: auto;
}



.barIndicators {
  display: flex;
  justify-content: space-between;
  color:#A09FB1;
  margin-bottom: 2px;
}

.barIndicators span {
  font-size: 12px;
  line-height: 14px;
}

.barItself {
  height:8px;
  width: 100%;
  border-radius: 4px;
  background: #E7E7EB;

}

.valueBar{
  background: #FFEC65;
  border-radius: 4px;
  height:8px;
  display: block;
  width: 0px;
  transition: all .5s ease-in;

  
}

.percent-symbol{
  text-align: right;
  color:#A09FB1;
}